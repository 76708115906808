import { motion } from "framer-motion";
import styled, { ThemeProvider } from "styled-components";

import * as s from "../styles/globalStyles";
import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import { lightTheme } from "./Themes";
import { useDispatch, useSelector, Provider } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import LogoComponent from "../subComponents/LogoComponent";
import Example from "../assets/images/example.gif";
import HomeButton from "../subComponents/HomeButton";
import BigTitle from "../subComponents/BigTitle";
import Minter from "../subComponents/Minter";
import fplogo from "../assets/images/foldedpaperlogo.png"
//Components

const Box = styled.div`
background-color: ${props => props.theme.body};
width: 100vw;
height: 100vh;
position: relative;
display: grid;
justify-content: space-evenly;
align-items: center;
flex-direction: row;


`;

const Main = styled(motion.div)`
  
  color: ${(props) => props.theme.text};
  
  padding: 2rem;
  
  z-index: 3;
  line-height: 1.5;
  align-items: center;


  //font-family: "Ubuntu Mono", monospace;

  display: flex;
  flex-direction: column;
  justify-content: none;

  &:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
    transition: all 0.2s ease;
  }
`;
const Main2 = styled(motion.div)`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};
  padding: 2rem;
  width: 40vw;
  height: 60vh;
  z-index: 3;
  line-height: 1.5;


  font-family: "Ubuntu Mono", monospace;

  display: flex;
  flex-direction: column;

  &:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
  }
`;



const Banner = styled.div`
color: ${(props) => props.theme.body};
background-size: cover;
display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
border: 2px solid;
height: 10vh;
width: 100%;
justify-content: center;
align-items: center;

`;

const Title = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: column;
font-size: 36px;
font-weight: bold;
`


const BulletPoints = styled.ul`

align-items: right;
justify-content: right;

`
const ListItems = styled.h2`
font-weight: bold;
`

const MintPage = () => {
  const [isShown, setIsShown] = useState(false);
  const [isShown2, setIsShown2] = useState(false);
  const data = useSelector((state) => state.data);


    return (

      <ThemeProvider theme={lightTheme}>
      
        <div className="header">
        <LogoComponent className=""/>
        <HomeButton className=""/>
        </div>
        <div className="box-grid">
        
        <img src={fplogo} className="img-logo"/>
        
        <Main className="testimonial">
        <h1>Every Paper Plane</h1>
        <ListItems>Grants:</ListItems>
        <ListItems>Early Access to Origami NFT drop</ListItems>
        <ListItems>A vote in Folded Paper DAO governance</ListItems>
        <ListItems>Folded Paper Air Drops in 2022</ListItems>
        <ListItems>A Portion of Folded Paper salaries</ListItems>
        </Main>
        <Main className="testimonial">
        {/* <Minter/> */}
        <h1 style={{marginTop:"2rem"}}>Mint Dropping Soon</h1>
        </Main>
        <img src={Example} className="img-example"/>
        </div>
      
        </ThemeProvider>

    )


}

export default MintPage