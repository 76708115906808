import * as React from "react"

export const PowerBtn = (props) => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={29.51}
          height={26.29}
          {...props}
        >
          <path d="M19.72 5.66L1.66.48 0 0l.85 1.5.5.88 12.09 21.26 3.37 2.65 3.66-10.19 9-5.95-3.33-2.66zm-5.27 4.93L4.22 2.47l17.94 5.12-7.71 3-.8 1-1 8.18-8.53-15-.74-1.28-.85-1.5.85 1.5 10.27 8.1zm12.64-.54l-7.25 4.78-.57.37-.24.65-2.93 8.17-2.33-1.83 1.2-9.6 4.06 3.2.24-.65.57-.37-4.07-3.18 9-3.41z" />
        </svg>
      )
}

export const Twitter = (props) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
    </svg>
  )
}
export const Discord = (props) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20.317 4.37a19.791 19.791 0 00-4.885-1.515.074.074 0 00-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 00-5.487 0 12.64 12.64 0 00-.617-1.25.077.077 0 00-.079-.037A19.736 19.736 0 003.677 4.37a.07.07 0 00-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 00.031.057 19.9 19.9 0 005.993 3.03.078.078 0 00.084-.028c.462-.63.874-1.295 1.226-1.994a.076.076 0 00-.041-.106 13.107 13.107 0 01-1.872-.892.077.077 0 01-.008-.128 10.2 10.2 0 00.372-.292.074.074 0 01.077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 01.078.01c.12.098.246.198.373.292a.077.077 0 01-.006.127 12.299 12.299 0 01-1.873.892.077.077 0 00-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 00.084.028 19.839 19.839 0 006.002-3.03.077.077 0 00.032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 00-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z" />
    </svg>
  )
}

export const PlaneCircle = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1000 1000"
      {...props}
    >
      <path 
      fill={props.fill}
      d="M500 0C223.86 0 0 223.86 0 500s223.86 500 500 500 500-223.86 500-500S776.14 0 500 0zM115.28 486.47l83.34-58 213.07 71.93-149.86 104.3zm311.81 270.32l-83.34 58-59.94-178.5L433.67 532zm16.56-20.86L478.18 501l373.3-283.2L456.2 469.44l-232.27-49.25L851.48 217.8z" />
    </svg>
  )
}

export const Plane = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
    width="741.77" 
    height="980.8" 
    viewBox="0 0 741.77 980.8"
    {...props}
    >
      <title>plane</title>
      <path
      d="M512,21.6,141.11,928.76v73.64L512,925.63l370.89,76.77V928.76ZM771.32,770.47,536.9,548.82,529,182ZM495,182l-7.9,366.78L252.68,770.47ZM478.63,880.23,188.72,942.47V886.73l289.91-256Zm356.65,62.24L545.37,880.23V630.77l289.91,256Z" 
      transform="translate(-141.11 -21.6)"
      />
    
    
    </svg>
    )
}


