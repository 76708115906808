
export const lightTheme = {

    body:"#ECEFF5",
    text:"#000000",
    fontFamily: "'Source Sans Pro', sans-serif",
    bodyRgba: "253, 255, 252",
    textRgba: "0,0,0",
  

}

export const darkTheme = {

    body:"#000000",
    text:"#ECEFF5",
    fontFamily: "'Source Sans Pro', sans-serif",
    textRgba: "253, 255, 252",
    bodyRgba: "0,0,0",
    
}

export const mediaQueries = key => {
    return style => `@media (max-width: ${key}em) { ${style} }`
  }