import React, { Component } from 'react'
import styled, { ThemeProvider } from "styled-components"
import { darkTheme, lightTheme } from '../components/Themes'
import Headshot from "../assets/images/headshot.jpg"
import FPLogo from "../assets/images/foldedpaperlogo.png"
import AboutFp from './AboutFp'

const Row = styled.div`
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};

align-items: center;
text-align: center;

`
const TextBox = styled.div`
width: 50%;
`
const PicContainer = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
`
const Title = styled.h1`
position: relative;


`
const Title2 = styled.h2`
position: relative;

`
const Description = styled.p`

`
const HeadShot = styled.img`
height: 15vw;
position: relative;
box-shadow: 0px 0px 10px #ccc6c6;

border-radius: 15vw;
`

class AboutMe extends Component {
    render() {
        return (
            <ThemeProvider theme={lightTheme}>

                <div class="box-grid2" style={{textAlign: "justify", textJustify: "inter-character"}}>
              
                <Row className="post2">
                    <h1>WHO MADE THIS?</h1>
                </Row>
                <img src={Headshot} className="img-headshot"/>
                <div className="post2">
                
                <h1>Hi, I'm Nick Dart</h1>
                <br/>
                <h2 style={{fontWeight:"bold", fontSize:"1.5rem"}}>Founder of Folded Paper</h2> 
                <p><br/>I’m an Australian born creator with lofty goals. I want Folded Paper to play a part in the proliferation of Decentralized Finance and to help bridge the gap between DeFi and the real world.</p>
                <p><br/>I have experience in animation, graphic design, game development, filmmaking, acting, screenwriting, consulting, photography, music production, and software development.</p>
                
                </div>
                <Row className="post2">
                    <h2 style={{fontWeight:"bold", fontSize:"1.5rem"}}>After trying so much, there’s only one thing I know for sure: <br/>I want to make things for the rest of my life</h2>
                </Row>
                <div className="post2">
                <p>From video games to agriculture tech. DeFi protocols to novel means of Energy Capture, or anything in between. As long as Folded Paper is building things, it will be a success. 
                <br/><br/>Folded Paper will be a generative force in both the DeFi space and the physical world. </p>
                </div>
                <div className="post2">
                <p>
                    <h2 style={{fontWeight:"bold", fontSize:"1.5rem"}}>
                    My goal,</h2> is to create an organization of like-minded and ambitious people who are ready to allocate time and thought to any and all innovative business ventures with the ultimate aim of granting all Folded Paper NFT holders &nbsp;
                    <h2 style={{fontWeight:"bold"}}> financial</h2> <h2 style={{fontWeight:"bold"}}>independence</h2> through the payout of dividends from our successful businesses, and the appreciation of our non-fungible assets.
                    </p>
                </div>

                <div className="post2" style={{marginBottom:"1.5em"}}>
                    <p>
                    <h2 style={{fontWeight:"bold", fontSize:"1.5rem"}}> Folded Paper
                    </h2> will be an experiment in democratized business, creating opportunities for self-sovereign work, and new ways to capture value. 
                    </p>
                    <p><br/>The Folded Paper DAO will help guide the world toward a utopian, decentralized future where value is created sustainably, and people acting in self interest helps humanity instead of harms it.
</p>
                </div>
                </div>
                
            
            </ThemeProvider>
        )
    }
}

export default AboutMe
