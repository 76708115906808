import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Discord, Twitter } from '../components/AllSvgs'
import { darkTheme } from '../components/Themes'

const Icons = styled.div`
display: flex;
flex-direction: column;
align-items: center;

position: fixed;
bottom: 0;
left: 2rem;

z-index: 3;

&>*:not(:last-child){
    margin: 0.5rem 0;
};
`

const Line = styled.span`
width: 2px;
height: 4vh;

background-color: ${props => props.color === 'dark' ? darkTheme.text : darkTheme.body };
transition: background-color 1s ease 1s;

@media only screen and (max-width: 50em) {
    background-color: ${props => props.color === 'dark' ? darkTheme.body : darkTheme.body };
  }
`
const SocialIcons = (props) => {
    return (
        <Icons>
        
            <div>
                <a style={{color:'inherit'}} href="https://discord.gg/qPnfmDVcrE" target='_blank'>
                    <Discord width={25} height={25} className ={props.theme === "dark" ? "socials1": "socials2" } />
                </a>
            </div>
        
            <div>
                <a style={{color:'inherit'}} href="https://twitter.com/DickNart" target='_blank'>
                    <Twitter width={25} height={25} className ={props.theme === "dark" ? "socials1": "socials2" } />
                </a>
            </div>
            
            <Line color={props.theme}/>

        </Icons>
    )
}

export default SocialIcons
