import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { lightTheme } from '../components/Themes'
import Logo from '../assets/images/foldedpaperlogo.png'

const Row = styled.div`
width: 100vw;
transform: 10;
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};

flex-direction: row;
justify-content: center;

`
const PicContainer = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
`
const PlanePic = styled.img`
width: 50vw;
position: relative;

`

const FoldedPaperBanner = () => {
    return (
        <ThemeProvider theme={lightTheme}>
        <Row>
        <PicContainer>
                <PlanePic src={Logo}/>
        </PicContainer>
            <br/><br/>
        <Row className="post">
            <h1 style={{justifySelf: "center"}}>WHO MADE THIS?</h1>
            <br/><br/><br/><br/>
        </Row>
        </Row>
        </ThemeProvider>
    )
}

export default FoldedPaperBanner
