import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Plane, PlaneCircle } from "../components/AllSvgs";
import { lightTheme } from "../components/Themes";

const Home = styled.button`
position: fixed;
top: 2rem;
left: 50%;
transform: translate(-50%, 0);

background-color: #f5f0f0;
padding: 0.3rem;
border-radius: 50%;
border: none #000;
width: 2.5rem;
height: 2.5rem;

display: flex;
justify-content: center;
align-items: center;
z-index: 3;

curser: pointer;

&:hover{
    
    box-shadow: 0 0 8px 6px rgba(111,111,111,0.6);
}
`


const HomeButton = () => {
    return (
        <Home>
        <NavLink to="/" >
            <PlaneCircle width={44} height={44} fill='#000' />
        </NavLink>
        </Home>
    )


}

export default HomeButton