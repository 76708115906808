import React, { Component } from "react"
import styled, { ThemeProvider } from "styled-components"
import ReactGA from 'react-ga';
import $ from 'jquery';

import { darkTheme, lightTheme } from "./Themes";
import HomeButton from "../subComponents/HomeButton"
import LogoComponent from "../subComponents/LogoComponent"
import { motion } from "framer-motion"
import Gallery from '../subComponents/Gallery'

import "../styles/themeClasses.css";
import * as s from "../styles/globalStyles";
import Header from "../subComponents/Header";
import AboutMe from "../subComponents/AboutMe";
import AboutFp from "../subComponents/AboutFp";
import SocialIcons from "../subComponents/SocialIcons";
import FoldedPaperBanner from "../subComponents/FoldedPaperBanner";



const Main = styled.div`

color: ${(props) => props.theme.text};
background-color: ${(props) => props.theme.body};
padding-top: 1rem;
width: 100vw;
z-index: 3;
line-height: 1.5;
position: relative;
top: 10%;

h1,h2,h3,h4,h5,h6,span,p{
  
}

`;



const AboutPage = (props) => {
  return (
  <ThemeProvider theme={lightTheme}>

    <div className="header2">
        <LogoComponent className=""/>
        <HomeButton className=""/>
        
    </div>
  <div className="box-grid2">
  <AboutFp/>
  <AboutMe/>
  <Gallery/>
  <div className="post2">
                <p> <h2 style={{fontWeight:"bold"}}>Pro Tip:</h2> Right-click save to get in early! </p>
                </div>
    <div/>
 </div>
 
  <SocialIcons/>
  
  </ThemeProvider>
  )
}

export default AboutPage