import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Box = styled.a`
width: calc(10rem + 15vw);
text-decoration: none;
height: 20rem;
padding: 1rem;
color: ${props => props.theme.text};
border: 2px solid ${props => props.theme.text};
backdrop-filter: blur(8px);
box-shadow: 0 0 1rem 0 rgba(0,0,0,0.2);

display: flex;
flex-direction: column;
z-index: 5;
&:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
    transition: all 0.5s ease;
  }
`
const Image = styled.div`
background-image: ${(props) => `url(${props.img})`};
background-repeat: no-repeat;
width: 100%;
height: 60%;
background-size: cover;
border: 1px solid transparent;
background-position: center center; 

`
const Title = styled.h2`
color: inherit;
padding 0.5rem 0;
padding-top: 1rem;
font-family: 'Karla', sans-serif;
font-weight: 700;
border-bottom: 2px solid ${props => props.theme.text};

`
const HashTags = styled.div`
padding: 0.5rem 0;

`
const Tag = styled.span`
padding-rightL 0.5rem;
`
const Date = styled.span`
padding: 0.5rem 0;
`
const BlogComponent = (props) => {
    const {name, tags, date, imgSrc, link} = props.blog;
    
    return (
        <Box target="_blank" href={link}>
            <Image img={imgSrc} alt="image" />
            <Title style={{
                fontSize: 22,
            }}>{name}</Title>

<HashTags>
          {tags.map((t, id) => (
            <Tag key={id}>#{t}</Tag>
          ))}
        </HashTags>
            <Date>
                {date}
            </Date>
        </Box>
    )
}

export default BlogComponent
