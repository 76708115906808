import React, { Component } from 'react'
import styled, { ThemeProvider } from "styled-components"
import { darkTheme, lightTheme } from '../components/Themes'
import Example from "../assets/images/example.gif"
import Logo from "../assets/images/foldedpaperlogo.png"
import FPLogo from "../assets/images/foldedpaperlogo.png"
const Row = styled.div`
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};

align-items: center;
text-align: center;
`
const Row2 = styled.div`
width: 100vw;
transform: 10;
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};
box-shadow: 0px 0px 10px black;
flex-direction: row;
justify-content: center;

`
const Title = styled.h1`
position: relative;
left: 10%;
width: 85vw;
`
const Title2 = styled.h2`
position: relative;
left: 10%;
font-size: 130%;
width: 85vw;
`
const Description = styled.p`
position: relative;
left: 10%;
width: 85vw;
`
const PicContainer = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
`
const PlanePic = styled.img`
width: 50vw;
position: relative;

`

class AboutFp extends Component {
    render() {
        return (
            <ThemeProvider theme={lightTheme}>
            
            <div class="box-grid2" style={{textAlign: "justify", textJustify: "inter-character"}}>
                <img className="img-col2" style={{paddingTop: "6em"}} src={FPLogo}/>
                <Row className="post2">
                    <h1>WHAT IS FOLDED PAPER?</h1>
                </Row>
                
                <div className="post2">
                <p><h2 style={{fontWeight:"bold", fontSize:"1.5rem"}}>Folded Paper</h2> is a series of NFT projects whose owners will govern an experimental <h2 style={{fontWeight:"bold", fontSize:"1.5rem"}}>Venture Capital Fund</h2> which will invest in small businesses and startups in order to create streams of revenue and returns on investment.</p>
                </div>
                
                <div className="post2">
                <p><h2 style={{fontWeight:"bold", fontSize:"1.5rem"}}>The first</h2> drop is a batch of 1000 Paper Plane NFTs (they're pretty sweet, check out the gallery at the bottom of this page.). Each represents life-time membership to Folded Paper, and will grant early access to future drops, as well as a portion of profits generated by the Folded Paper Fund.</p>
                </div>

                <Row className="post2">
                    <h1>Why Venture Capital?</h1>
                </Row>
                <div className="post2">
                <p><h2 style={{fontWeight:"bold", fontSize:"1.5rem"}}>Venture Capital</h2> is a form of private equity which fuels startups and small businesses that are believed to have the potential for long-term growth. Folded Paper NFT holders will vote on which businesses we should allocate our capital to, as well as help locate potential investments.</p>
                </div>
                <div className="post2">
                <p><h2 style={{fontWeight:"bold", fontSize:"1.5rem"}}>Folded Paper</h2> members believe that decentralized finance can revolutionize the capital markets. As a Venture Capital fund, Folded Paper will be able to out compete traditional VCs thanks to the speed at which we can raise capital, as well as the breadth of experience our many members provide.</p>
                </div>
                <div className="post2">
                <p><h2 style={{fontWeight:"bold", fontSize:"1.5rem"}}>Folded Paper</h2> will start small, building streams of passive income through creating and acquiring brands and small businesses, and will eventually expand to ambitious, large scale research and development projects.</p>
                </div>

                <Row className="post2">
                    <h1>VALUES</h1>
                </Row>
                <div className="post2">
                <h1>Sustainability</h1>
                    <p>More than just an environmental buzzword. We want to ensure sustainability in the way that we generate value. Zero sum games are old hat, why can’t we build economies that produce value outside of the framework of “my gain must be someone’s loss.”? </p>
                    
                </div>
                <div className="post2">
                    <h1>Decentralization</h1>
                    <p>We will prove that decentralized organizations can outperform and out compete establishment, hierarchical business. We believe that the incentive structures of Traditional Finance will soon be obsolete. Furthermore, the ability to rapidly prototype new governance structures will allow DAOs to experiment with social technology in a way that centralized businesses can’t.</p>
                    </div>
                    <div className="post2">
                    <h1>Creativity</h1>
                    <p>Creativity is at the very core of being human. Everyone should be able to make stuff. Even the most task oriented, self-diagnosed non-creative has immense creative potential. Everyone should be encouraged to write, draw, build, paint, play, think and learn creatively. And the only way we can ensure Folded Paper members are able to maximize their creativity is through…</p>
                    </div>
                    <div className="post2">
                    <h1>Financial Freedom</h1>
                    <p>We believe in helping all Folded Paper members achieve financial freedom. Folded Paper will be able to take the kinds of risks necessary to solve big real-world problems only if all members of the collective are financial-stress free, and free from long hours of value stripped labor.  </p>
                    </div>
                    <div className="post2">
                    <h1>Health</h1>
                    <p>Physical and mental health of our community is tantamount to our success. Healthy members can think long term. We are in this for the long haul. Finding novel ways to incentivize healthy living and exercise will be an early priority.</p>
                    </div>
                
                </div>
                
            </ThemeProvider>
        )
    }
}

export default AboutFp
