import React from 'react'
import ImageGallery from 'react-image-gallery';
import styled, { ThemeProvider } from 'styled-components';
import { lightTheme } from '../components/Themes';

const Row = styled.div`
background-color: ${props => props.theme.text};
color: ${props => props.theme.body};

align-items: center;
text-align: center;

`
  

const Gallery = () => {
    return (
        <ThemeProvider theme={lightTheme}>
        <div className="gallery">
            <Row className="post2">
                <h1>GALLERY</h1>
            </Row>
            <Row className="post2">
                <h2 style={{fontSize:"1.1em", fontWeight:"bold"}}>Here's a sneak peak at the Paper Plane NFT collection.</h2>
            </Row>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf5.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/6fcc1445-ccb2-4f88-b347-f7845f22a29f_rw_1920.png?h=c6ffaf803842c4151e5dcbf7fa656175' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/8432769f-aa58-4532-a35e-d1951bf5f58c_rw_1920.png?h=46d5b2420a30e0d2c198f7b56ab57586' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/8d279b82-4858-46c3-851b-0fef0f2e0d41_rw_1920.png?h=72bb852b56fd1b9885a0578b12fa35a4' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/bdca13ab-9429-43e6-a7f9-88f5a8285044_rw_1920.png?h=96802e73f13c91c9d8387829b0c84699' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf3.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/4a25fab5-4d25-4941-b090-7c17eb382a20_rw_1920.png?h=53ee45182b26f4c6c7953ee2aee1e5de' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf6.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/af585837-fa95-42d0-b7c5-878813347da1_rw_1920.png?h=b67c8a56f4e806fef47538d94ebe0692' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/b472861d-50d1-4e42-a0f7-ca8c2aba90c2_rw_1920.png?h=87eeaaf86bc528e8f6c5786dd3fe9e52' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf6.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/7a5e3a6a-c75f-47ac-963e-7974b4fe5b25_rw_1200.png?h=23625d01aca230d952e6cf46268f94fc' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf2.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/dd888655-1630-4a5d-9a03-87e3be1df650_rw_1920.png?h=6b318063dc38c9435247de75c4b7bcb0' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf6.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/03425337-3fef-4fcd-bb5e-5e665508c6ef_rw_1920.png?h=e6a8cd7194977d43eb85f1d12f1d4950' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf6.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/0f7dba47-27f2-4f55-8d4e-0152f010abaa_rw_1920.png?h=5589561659b04e910052d8ec50d06d98' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf3.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/e1699e85-7911-4396-b4c8-5f314ab8f277_rw_1920.png?h=6a5b15e096d746462cc328b3b096b142' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/b292d449-de7f-4b11-8a42-b47c18dcf92a_rw_1920.png?h=c7f0f03cb18668a0396666e443fe8858' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf3.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/4d7c9584-97a9-4dbf-a58d-14d86ecfdae2_rw_1920.png?h=5d46464b87e88a9092903b164d845b57' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/b5c44ae6-b867-454d-becb-2aeb7d4929b0_rw_1920.png?h=96109b89dfcf1e69c41a2d267ab757ec' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf6.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/737c5efd-8d27-417a-b67b-d846b750613a_rw_1920.png?h=f89da1a87582857d9801c3cf70d33a15' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf4.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/f7a59934-dd90-4f7d-8259-8123d70dbab9_rw_1920.png?h=63ca17bb56e40cab1229b3e58e585424' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf6.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/a1330a45-2edc-4875-b262-7bfbb4f60f53_rw_1920.png?h=5f8cc1ac3461017b6f02bf817f5a05ac' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf3.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/e2802323-a132-4882-b348-b1ed5c4f7f6b_rw_1920.png?h=59b5fa544316e8d5ad37b52cb1dbc766' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf6.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/a6659770-f611-46df-bc75-f38c528937e1_rw_1920.png?h=a7830fbc8966141bdc2fb5a2c74375c1' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/84eddc66-aab5-4240-83af-7d84e639574d_rw_1920.png?h=9b37ae2e01b007385fc25c412021dc9a' style={{width: "100%"}}/>
                </div>
            </div>
            <div className="pictureBox">
                <div className="content">
                <img src='https://pro2-bar-s3-cdn-cf4.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/571445e5-4e2b-40f1-9ed8-5bd9928854bc_rw_1920.png?h=d668399dea2bb9c6456537acd58bb61f' style={{width: "100%"}}/>
                </div>
            </div>
        </div>
        </ThemeProvider>
    )
}

export default Gallery
