import { Route, Routes } from "react-router-dom"
import { Router } from "react-router";
import GlobalStyle from "./styles/globalStyles"
import { lazy, Suspense } from "react";
import { ThemeProvider } from "styled-components"
import { lightTheme, darkTheme } from "./components/Themes"
import { Provider } from "react-redux";
import store from "./redux/store";
//Components
import Main from './components/Main'
import AboutPage from './components/AboutPage'
import ReadingPage from './components/ReadingPage'
import MintPageWrapper from "./components/MintPage";
import VisionPage from "./components/VisionPage";
import GalleryPage from "./components/GalleryPage";
//const Main = lazy(() => import("./components/Main"));

function App() {
  return(

   <>

  <GlobalStyle/> 

    <ThemeProvider theme={lightTheme}>

      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route exact path="/vision" element={<VisionPage />} />
        <Route exact path="/mint" element={
        <Provider store={store}>
            <MintPageWrapper />
        </Provider>
        } />
        <Route exact path="/read" element={<ReadingPage />} />
        <Route exact path="/gallery" element={<GalleryPage />} />
      </Routes>

    </ThemeProvider>


    </>
    );
} 

export default App