import {motion} from 'framer-motion';
import React from "react"
import styled, { keyframes } from "styled-components"
import HomeButton from "../subComponents/HomeButton"
import LogoComponent from "../subComponents/LogoComponent"
import LogoComponentLong from "../subComponents/LogoComponentLong"
import SocialIcons from "../subComponents/SocialIcons"
import { Link, NavLink } from "react-router-dom"
import { Plane } from "./AllSvgs"
import { useState, Component } from "react"
import Intro from "./Intro"
import { mediaQueries } from "./Themes";


const MainContainer = styled.div`
background: ${props => props.theme.body};
width: 100vw;
height: 100vh;
overflow: hidden;
position: relative;

h1,h2,h3,h4,h5,h6,span{
    font-family: 'Karla', sans-serif;
    font-weight:800;
}
h2 {
    ${mediaQueries(40)`
      font-size:1.2em;

  `};

    ${mediaQueries(30)`
      font-size:1em;

  `};
  }

`;
const Container = styled.div`
padding: 2rem;
`
const Contact = styled.a`
color: ${props => props.theme.text};
position: absolute;
top: 2rem;
right: calc(1rem + 2vw);
text-decoration: none;
z-index: 5;
`;
const READ = styled(NavLink)`
color: ${props => props.theme.text};
position: absolute;
top: 50%;
right: calc(1rem + 2vw);
transform: rotate(90deg) translate(-50%, -50%);
text-decoration: none;
z-index: 2;

animation: ${(props) => (props.click ? "pulse-white 2s infinite" : "none")};
text-decoration: none;
@media only screen and (max-width: 50em) {
    text-shadow: ${(props) => (props.click ? "0 0 4px #000" : "none")};
    
    color: ${props => props.click ? props.theme.body : props.theme.text };
}
@keyframes pulse-white {
    0% {
      transform: rotate(90deg) scale(1);
      
    }
    
    70% {
      transform: rotate(90deg) scale(1.25);
      
    }
    
    100% {
      transform: rotate(90deg) scale(1);
      
    }
  }
  
`;
const MINT = styled(NavLink)`
color: ${props => props.click ? props.theme.body : props.theme.text };
position: absolute;
top: 50%;
left: 2rem;
transform: rotate(-90deg) translate(-50%, -50%);
text-decoration: none;
z-index: 2;
@media only screen and (max-width: 50em) {
    text-shadow: ${(props) => (props.click ? "0 0 4px #000" : "none")};
  }
`;
const BottomBar = styled.div`
  position: fixed;
  bottom: 2vh;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: space-evenly;
`;

const ABOUT = styled(NavLink)`
color: ${props => props.theme.text};
text-decoration: none;
z-index: 2;
`;

const GALLERY = styled(NavLink)`
color: ${props => props.click ? props.theme.body : props.theme.text };

left: 2rem;
text-decoration: none;

@media only screen and (max-width: 50em) {
    text-shadow: ${(props) => (props.click ? "0 0 4px #000" : "none")};
  }
`;

const rotate = keyframes`
from{
    transform: rotate(0deg);
}
to{
    transform: rotate(360deg);
}
`;
const Center = styled.button`
position: absolute;
top: ${props => props.click ? '85%' : '50%' };
left: ${props => props.click ? '92%' : '50%' };
transform: translate(-50%,-50%);
border: none;
outline: none;
background-color: transparent;
cursor: pointer;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
transition: all 1s ease;

&>:first-child{
    animation: ${rotate} infinite 9.0s linear;

}
&>:last-child{
    display: ${props => (props.click ? 'none' : 'inline-block')};
    padding-top: 1rem;

}
@media only screen and (max-width: 50em) {
    top: ${(props) => (props.click ? "90%" : "50%")};
    left: ${(props) => (props.click ? "90%" : "50%")};
    width: ${(props) => (props.click ? "80px" : "150px")};
    height: ${(props) => (props.click ? "80px" : "150px")};
  }
  @media only screen and (max-width: 30em) {
    width: ${(props) => (props.click ? "40px" : "150px")};
    height: ${(props) => (props.click ? "40px" : "150px")};
  }
`;

const DarkDiv = styled.div`
position: absolute;
top: 0;
background-color: #000;
bottom: 0;
right: 50%;
width: ${props => props.click ? '50%' : '1%' };
height: ${props => props.click ? '100%' : '0%' };
z-index: 2;
transition: height 0.5s ease, width 1s ease 0.5s;
${(props) =>
    props.click
      ? mediaQueries(50)`
       height: 50%;
  right:0;
  
  width: 100%;
  transition: width 0.5s ease, height 1s ease 0.5s;

  `
      : mediaQueries(50)`
       height: 0;
  
  width: 0;
  `};
`

const Main = () => {

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click)


    return (
        <MainContainer>
          <DarkDiv click={click} />
            <Container>
            <LogoComponent/>
            <HomeButton />
            <SocialIcons theme={click ? "dark" : "light" } />

            <Center click={click}>
                <Plane onClick={()=> handleClick()} width={click ? 120 : 200} height={click ? 120 : 200} fill='current color'/>
                <span>Click here</span> 
            </Center>

            <Contact href="mailto:contactfoldedpaper@gmail.com" target ="_blank">
                <motion.h2
                style={{scale: 1.3}}
                whileHover={{scale: 1.6}}
                whileTap={{scale:0.9}}
                >
                    Say hi..
                </motion.h2>
            </Contact>
            <READ to="/about" click={click}>
                <motion.h2
                
                style={{scale: 1.3}}
                whileHover={{scale: 1.6}}
                whileTap={{scale:0.9}}
                >
                    About
                </motion.h2>
            </READ>
            <MINT to="/mint" click={click}>
                <motion.h2
                
                style={{scale: 1.3}}
                whileHover={{scale: 1.6}}
                whileTap={{scale:0.9}}
                >
                    Mint
                </motion.h2>
            </MINT>
            <BottomBar>
            {/* <VISION to="/vision" click={click}>
                <motion.h2
                
                style={{scale: 1.3}}
                whileHover={{scale: 1.6}}
                whileTap={{scale:0.9}}
                >
                        Vision
                </motion.h2>
            </VISION> */}
            <GALLERY to="/gallery" click={click}>
                <motion.h2
                
                style={{scale: 1.3}}
                whileHover={{scale: 1.6}}
                whileTap={{scale:0.9}}
                >
                        Gallery
                </motion.h2>
            </GALLERY>
            <ABOUT to="/read">
                <motion.h2
                
                style={{scale: 1.3}}
                whileHover={{scale: 1.6}}
                whileTap={{scale:0.9}}
                >
                        Learn
                </motion.h2>
            </ABOUT>
            </BottomBar>
            </Container>
            
            {click ? <Intro click={click} /> : null }


        </MainContainer>


    )


}

export default Main