export const Blogs = [
    {
        id:1,
        name:"NFTs Explained",
        tags:["DeFi ","NFTs ","Explained "],
        date:"August, 2021",
        imgSrc:"https://pro2-bar-s3-cdn-cf6.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/7f883d9d-fdff-489f-9434-e39f6a37a9db_rw_1920.jpg?h=28bf1d3d894401dee012095b78bcf87d",
        link:"https://www.theverge.com/22310188/nft-explainer-what-is-blockchain-crypto-art-faq"
    },
    {
        id:2,
        name:"Bitwise DeFi: A Primer for Professional Investors",
        tags:["DeFi ","Finance ","Explained "],
        date:"November, 2021",
        imgSrc:"https://drive.google.com/uc?export=view&id=1vfZbJvjNNJVthJaxPC2x9j9k_71stxZM",
        link:"https://s3.amazonaws.com/static.bitwiseinvestments.com/Research/Decentralized-Finance-DeFi-A-Primer-for-Professional-Investors-11-16-2021.pdf"
    },
    {
        id:3,
        name:"Bitwise DeFi: A Primer for Professional Investors",
        tags:["Governance ", "Vitalik ", "Explained"],
        date:"August, 2014",
        imgSrc:"https://drive.google.com/uc?export=view&id=1uYT0LQEztN0SBlypzmTqAM8H8FRSiBh4",
        link:"https://blog.ethereum.org/2014/08/21/introduction-futarchy/"
    },
    {
        id:4,
        name:"Vitalik Buterin: Moving beyond coin voting governance ",
        tags:["Governance  ","Vitalik ","Explained "],
        date:"August, 2021",
        imgSrc:" https://drive.google.com/uc?export=view&id=1CzEC__4RwHzhHoMLMH3UE4iJ8YYzucJY",
        link:"https://vitalik.ca/general/2021/08/16/voting3.html"
    },
    {
        id:5,
        name:"Squad Wealth. ",
        tags:["Governance  ","DeFi ","Explained "],
        date:"August, 2020",
        imgSrc:" https://pro2-bar-s3-cdn-cf5.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/6e170165-9db0-4d7a-9820-0d225db08c59_rw_1920.jpg?h=d6a7912228e6581e773a445201da268e",
        link:"https://otherinter.net/research/squad-wealth/"
    },
    
   
  
] 