import styled from "styled-components";

import { NavLink } from "react-router-dom";
import logoLong from '../assets/images/foldedpaperlogo.png';


const LogoBtn = styled.button`
position: fixed;
top: 2rem;
left: 2rem;

background-color: transparent;
padding: 0.0rem;
border: none;
width: 2.5rem;
height: 2.5rem;
diplay: flex;
justify-content: center;
align-items: center;
z-index: 3;

curser: pointer;


`


const LogoComponentLong = (props) => {
  
  return (
    <LogoBtn>
    <NavLink to="/" >
    <img src={logoLong} alt="Logo"
    style={{
      width: 300,
      height: 75,
    }}
    />
    </NavLink>
    </LogoBtn>


)


};

export default LogoComponentLong;
