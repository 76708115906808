import React from "react"
import styled from "styled-components"
import img from "../assets/images/background.png"
import HomeButton from "../subComponents/HomeButton"
import LogoComponent from "../subComponents/LogoComponent"
import SocialIcons from "../subComponents/SocialIcons"

import {Blogs} from '../subComponents/BlogData';
import BlogComponent from "../subComponents/BlogComponent"

const MainContainer = styled.div`
background-image: url(${img});
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
width: 100vw;


`

const Container = styled.div`
background-color: ${props => `rgba(${props.theme.bodyRgba},0.8)`};
width: 100%;
height: 100vh;

position: relative;
padding-bottom: 50rem;
`
const Center = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding-top: 10rem;
`

const Grid = styled.div`
display: grid;
grid-template-columns: repeat(1, minmax(calc(10rem + 15vw), 1fr));
grid-gap: calc(1rem + 2vw);
`

const ReadingPage = () => {
    return (
        <MainContainer>
            
            <Container>
                <LogoComponent />
                <HomeButton />
                <SocialIcons/>

            <Center>
            <Grid>
                {
                    Blogs.map((blog) => (
                        <BlogComponent key={blog.id} blog={blog} />
                    ))
                }

            </Grid>
            </Center>
            </Container>
        </MainContainer>


    )


}

export default ReadingPage