import {motion} from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import Example from '../assets/images/plane-img.png';
import { mediaQueries } from './Themes';
const Box = styled(motion.div)`
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);

width: 55vw;
height:65vh;
display: flex;
z-index: 1;
background-repeat: no-repeat;
background-size: 100% 2px;

background: linear-gradient(
    to right,
    ${props => props.theme.body} 50%,
    ${props => props.theme.text} 50%
    ) 
    bottom, 
    linear-gradient(
    to right,
    ${props => props.theme.body} 50%,
    ${props => props.theme.text} 50%) top;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    border-right: 2px solid ${props=> props.theme.text};
    border-left: 2px solid ${props=> props.theme.body};
    
    z-index: 3;

    ${mediaQueries(1200)`
    width: 65vw;
  `};

  ${mediaQueries(60)`
    width: 70vw;
  `};

  ${mediaQueries(50)`
    width: 50vw;
    background-size: 100% 2px;

    flex-direction:column;
    justify-content:space-between;
  `};

  ${mediaQueries(40)`
    width: 60vw;
  `};

  ${mediaQueries(30)`
    width: 70vw;
  `};
  ${mediaQueries(20)`
    width: 60vw;
  `};

  @media only screen and (max-width: 50em) {
    background: none;
    border: none;
    border-top: 2px solid ${(props) => props.theme.body};
    border-bottom: 2px solid ${(props) => props.theme.text};
    background-image: linear-gradient(
        ${(props) => props.theme.body} 50%,
        ${(props) => props.theme.text} 50%
      ),
      linear-gradient(
        ${(props) => props.theme.body} 50%,
        ${(props) => props.theme.text} 50%
      );
    background-size: 2px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat;
  }

`;

const SubBox = styled.div`
width: 50%;
position: relative;
display: flex;
z-index: 3;
.pic{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 80%;
    height: auto;
    z-index: 1;
}
${mediaQueries(50)`
      width: 100%;
    height: 50%;
      .pic {
    
    width: 40%;
    
  }

  `};

  ${mediaQueries(40)`
  
      .pic {
    
    width: 45%;
    bottom: 10;
    
  }

  `};

  ${mediaQueries(30)`
     

      .pic {
    
    width: 45%;
    
  }

  `};
  ${mediaQueries(20)`
     

     .pic {
   
   width: 45%;
   
 }

 `};

`
const Text = styled.div`
font-size: calc(1em + 1.5vw);
font-weight: bold;
color: ${props => props.theme.body};
padding: 2rem;


display: flex;
flex-direction: column;
justify-content: space-evenly;
z-index: 5;
&>*:last-child{
    color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
    font-size: calc(0.9rem + 1.5vw);
    font-weight:300;
    ${mediaQueries(40)`
    font-size: calc(0.5rem + 1vw);
`};
}
    
${mediaQueries(40)`
        font-size: calc(1rem + 1.5vw);


  `};
  ${mediaQueries(20)`
         padding: 1rem;



  `};


`

const Pic = styled.img`
position: relative;
z-index: 1;
`

const Intro = () => {
    return (
        <Box
        initial={{height:0}}
        animate={{height: '55vh'}}
        transition={{ type: 'spring', duration: 2, delay: 1}}
        >
            <SubBox>
                <Text>
                    <h1>Folded Paper</h1>
                    <h3>NFT backed Venture Capital.</h3>
                    <h6>The Future of Equity is Decentralized</h6>
                </Text>
            </SubBox>
            <SubBox>
                <motion.div
                initial={{opacity:0}}
                animate={{opacity: 1}}
                transition={{ duration: 1, delay: 1}}
                >
                    <Pic className="pic" src="https://pro2-bar-s3-cdn-cf6.myportfolio.com/d079b2e8-1955-4e2f-9926-10739fdc96fc/733f63ce-8a04-4458-a092-0e6b4ba1dfbc_rw_600.png?h=e217d2fbfc6f64325c2853f3326c4331" />
                </motion.div>
            </SubBox>
        </Box>
    )
}

export default Intro
