import React, { Component } from "react"
import styled, { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "../components/Themes";
import HomeButton from "./HomeButton";
import LogoComponent from "./LogoComponent";


const Main = styled.div`

width: 100vw;
height: 7rem;
top: 0;

background-color: ${props => props.theme.body};
color: ${props => props.theme.text};
box-shadow: 0px 10px 10px rgba(153, 147, 147, .2);
flex-direction: row;
justify-content: center;
`

class Header extends Component {
    render() {
  
      
  
      return (
        <ThemeProvider theme={lightTheme}>
        <Main className="header">
            <LogoComponent/>
            <HomeButton />
            
        </Main>
        </ThemeProvider>
      );
    }
  }
export default Header
