import React from 'react'
import { ThemeProvider } from 'styled-components'
import Gallery from '../subComponents/Gallery'
import HomeButton from '../subComponents/HomeButton'
import LogoComponent from '../subComponents/LogoComponent'
import { darkTheme, lightTheme } from './Themes'
import ImageGallery from 'react-image-gallery';





const GalleryPage = () => {
    return (
        <ThemeProvider theme={darkTheme}>
        
        <div className="header2">
        <LogoComponent/>
        <HomeButton/>
        </div>

        <div className="box-grid2">
            <Gallery/>
        </div>
       
        </ThemeProvider>
    )
}

export default GalleryPage
